<template>
  <div class="header" style="background-color: white; justify-content: space-between;">
    <a :href="loinfo.header_link">
      <img :src="loinfo.main_logo" style="padding-left: 5px" />
    </a>
    <div style="display: flex; flex-direction: row; height: 100%; justify-content: center; align-items: center;">
      <div class="two-pane-vertical lo-contact">
        <div class="" style="padding: 5px;">
          <strong>{{ loinfo.first_name }} {{ loinfo.last_name }}</strong>
        </div>
        <div>
          {{ loinfo.phone_number }}
        </div>
      </div>
      <img class="header-img" :src="loinfo.app_header_image ? loinfo.app_header_image : loinfo.lo_image" />
    </div>
  </div>

  <div v-if="!appSavable"
    id="widget"
    class="em-widget"
    data-width="100%"
    data-height="800px"
    data-min-height="800px"
    data-site-id="1297790669"
    data-name="ecc-loan-app"
    :data-config=appworkflow
    data-aria-label="My Loan Application"
  ></div>

  <div class="create-acct-notice" v-if="appSavable">

      <div class="loan-launch-description-container">
        <img class="loan-launch-logo" :src="loanLaunchLogoSource" />
        <p class="notice-header">Create a LoanLaunch account or log in to start your application</p>
        <p class="loan-launch-description">
          Loan Launch is your private and secure portal to monitor the status of your mortgage,
          securely send us documents, and chat with your loan team.
        </p>
        <div class="create-acct-btns-container">
          <k-button class="create-acct-btn" @click="goToSavableApp">Create Account</k-button>
          <p class="sign-in-container">Already have an account? <span class="sign-in-btn" @click="goToSavableApp" role="button">Log In</span></p>
        </div>
      </div>


  </div>
  <LOFooter :loinfo="loinfo"></LOFooter>
</template>

<script>
import { Button } from '@progress/kendo-vue-buttons';

import apiService from "../api/api.service.js";
//import navMenu from "../components/navMenu.vue";
import LOFooter from "../components/LOFooter.vue";

export default {
  data: function () {
    return {
      contactFormDialog: false,
      loinfo: {},
      larFromQuery: "",
      loanLaunchLogoSource: '/Loan Launch Logo.png',
      appworkflow:'',
      appSavable: false,
      showWidget: false,
      workflowId: null
    };
  },
  name: "ApplyView",
  props: {
    msg: String,
  },
  components: {
    LOFooter,
    'k-button': Button
  },
  methods: {
    async getLOInfo() {
      return apiService
        .GetLOPage(this.larFromQuery)
        .then((res) => {
          this.loinfo = res.data.data[0];
          document.title = "Mortgage Application";

           // Add Open Graph meta tags dynamically
          this.updateMetaTags({
            // Open Graph Tags
            "og:title": `${this.loinfo.first_name} ${this.loinfo.last_name} - Mortgage Application`,
            "og:description": `Apply now with ${this.loinfo.first_name} ${this.loinfo.last_name} for personalized mortgage services.`,
            "og:image": this.loinfo.app_header_image || this.loinfo.lo_image, // Use fallback if app_header_image is null
            "og:url": window.location.href, // Current page URL
            "og:type": "website",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToSavableApp() {
      this.$router.push(`/applysave?lar=${this.larFromQuery}&workflowid=${this.workflowId}`);
    },
    updateMetaTags(metaData) {
      // Remove existing dynamic meta tags to avoid duplicates
      const existingMetaTags = document.querySelectorAll('meta[data-dynamic="true"]');
      existingMetaTags.forEach((tag) => tag.remove());

      // Add each meta tag dynamically
      Object.entries(metaData).forEach(([key, content]) => {
        const meta = document.createElement("meta");

        if (key.startsWith("og:")) {
          // For Open Graph tags
          meta.setAttribute("property", key);
        } else {
          // For standard meta tags
          meta.setAttribute("name", key);
        }

        meta.setAttribute("content", content);
        meta.setAttribute("data-dynamic", "true"); // Mark for cleanup
        document.head.appendChild(meta);
      });
    }
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.larFromQuery = params.lar;
    this.workflowId = params.workflowid;
    this.appworkflow = '{"workFlowId":"' + params.workflowid + '","externalLoginWindow":"true"}'; 
    this.appSavable = params.savable;
  },
  async mounted() {
    await this.getLOInfo();
    this.$store.commit('updateLOInfo', this.loinfo);

    if (!this.appSavable) {
      this.showWidget = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/src/assets/css/homeView.css";
.em-widget {
  margin-top: 60px;
}

.header-img-container{
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  
}
.header-img{
  border-radius: 3px;
  object-fit: cover;
  padding-right: 15px;
  justify-content: center;
  align-items: center;
}
.lo-contact{
  margin: 20px 10px;
  font-size: 1rem;
}

.create-acct-notice {
  min-height: 450px;
  padding: 0 15px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-acct-notice .notice-header {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.create-acct-notice .loan-launch-description-container {
  max-width: 550px;
  padding: 20px 15px;
  margin: 15px 0;
  font-size: 1rem;
  background-color: #f0f6fe;
  line-height: 1.4;
    border: 1px solid #b9d6f9;
  border-radius: 8px;
}

.create-acct-notice .loan-launch-description-container .loan-launch-description {
  margin-bottom: 35px;
}

.create-acct-btn {
  background-color: #b3d4ff;
  font-weight: 600;
  padding: 8px;
  color: black;
  font-size: .9rem;
  margin: 0 10px;
  min-width: 160px;
}

.loan-launch-logo {
  width: 250px;
}

.sign-in-container {
  margin-top: 10px;
  margin-bottom: 5px;
}

.sign-in-btn {
  color: #0000FF;
  cursor: pointer;
  font-weight: 600;
  margin-left: 3px;
}

@media (max-width: 782px){
  .lo-contact {
    font-size: .7rem;
  }
}

@media (max-width: 735px){
  .header{
    padding: 0px;
  }
  .header-img{
    display: none;
  }
}
@media (max-width: 524px){
  .em-widget{
    margin-top: 90px;
  }

  .create-acct-notice .notice-header {
    font-size: .9rem;
  }

  .loan-launch-logo {
    width: 225px;
  }

  .create-acct-notice .loan-launch-description-container {
    font-size: .9rem;
    padding: 15px;
  }
}

@media (max-width: 500px){
  .lo-contact{
    width: 90px;
  }
  .em-widget{
    margin-top: 110px;
  }
}
@media (max-width: 450px){
  body{
    margin-top: 125px !important;
  }


}
</style>
