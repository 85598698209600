<template>
  <k-dialog
    v-if="visibleDialog"
    class="scheduler-dialog"
    :title-render="customTitleBar"
    @close="closeApptDialog"
    @overlayclick="closeApptDialog"
  >
    <template v-slot:titleTemplate>
      <div class="scheduler-title">
        Schedule
        <span v-if="interval != 0">{{ interval }} Minute</span> Appointment
      </div>
    </template>
    <div class="one-pane" style="width: 100%; height: 98%">
      <div v-if="durationActive" class="one-pane center-column">
        <h2>Meeting Duration</h2>
        <div class="duration-button" @click="getAvailability(30)">
          30 Minutes <i class="fa-duotone fa-clock" style="padding: 10px"></i>
        </div>
        <div class="duration-button" @click="getAvailability(60)">
          60 Minutes <i class="fa-duotone fa-clock" style="padding: 10px"></i>
        </div>
      </div>
      <div v-if="calendarActive" class="two-pane appointment-container">
        <div style="flex: 1">
          <VueDatePicker
            v-model="this.selectedDay"
            inline
            auto-apply
            :min-date="dateStart"
            :max-date="dateEnd"
            prevent-min-max-navigation
            :allowed-dates="availableBookingTimes"
            :enable-time-picker="false"
            @update:model-value="scroll('meetingAnchor')"
          ></VueDatePicker>
        </div>
        <div class="meeting-times-container">
          <div v-if="selectedDay === ''">
            <strong>Please select a day</strong>
          </div>
          <span id="meetingAnchor"></span>
          <h2 v-if="selectedDay != ''">Available Meeting Times</h2>
          <h2>
            {{
              selectedDay.toLocaleString("en-US", {
                weekday: "short",
                month: "2-digit",
                day: "2-digit",
              })
            }}
          </h2>
          <div class="scroll-container">
            <div
              v-for="item in availableBookingTimes"
              :key="item"
              class="center-column"
              style="margin: 0"
            >
              <div
                v-if="
                  item.toLocaleString('en-US', {
                    weekday: 'short',
                    month: '2-digit',
                    day: '2-digit',
                  }) ==
                  appointmentDay.toLocaleString('en-US', {
                    weekday: 'short',
                    month: '2-digit',
                    day: '2-digit',
                  })
                "
                class="time-button"
                @click="selectAppointment(item)"
              >
                <p>
                  {{
                    item.toLocaleString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="apptInfoActive" class="one-pane center-column" style="flex: 2">
        <div style="display: flex; flex-direction: row">
          <h3>
            {{
              selectedAppointment[0].toLocaleString("en-US", {
                weekday: "short",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
            }}
          </h3>
          <div
            style="
              display: flex;
              flex-direction: column;
              margin: 10px;
              align-items: center;
            "
          >
            <input
              type="checkbox"
              name="isOnline"
              v-model="isOnline"
              style="width: 20px"
            />
            <label for="isOnline">Online Meeting</label>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex: auto;
            flex-direction: column;
            width: 100%;
            margin: 5px 0;
          "
        >
          <div class="schedule-input-container">
            <input
              placeholder="Name"
              v-model="clientName"
              class="schedule-input-field"
            />
            <input
              placeholder="Email"
              v-model="clientEmail"
              class="schedule-input-field"
            />
          </div>
          <div class="schedule-input-container">
            <input
              placeholder="Subject"
              v-model="subject"
              class="schedule-input-field"
            />
            <input
              placeholder="Location"
              v-model="location"
              class="schedule-input-field"
            />
          </div>
          <div class="schedule-input-container">
            <textarea
              placeholder="Message"
              v-model="message"
              class="schedule-input-field"
            ></textarea>
          </div>
          <div class="schedule-input-container"></div>
          <div
            @click="bookAppointment"
            class="duration-button"
            style="margin: auto; height: auto"
          >
            Book
          </div>
        </div>
      </div>
      <div v-if="confirmationMsgActive" class="one-pane center-column">
        <h1>Appointment Booked</h1>
        <h2>A confirmation email has been sent to {{ clientEmail }}</h2>
        <div
          class="duration-button"
          @click="closeApptDialog"
          style="height: auto"
        >
          Close
        </div>
      </div>
    </div>
    <div
      class="back-button"
      v-if="!durationActive && !confirmationMsgActive"
      @click="goBack"
    >
      Back
    </div>
  </k-dialog>
  <k-dialog
    v-if="errorPopup"
    title="Error"
    @close="errorPopup = false"
    @overlayclick="errorPopup = false"
  >
    <div class="center-column message-popup">
      <h3 style="margin: 0">{{ errorMessage }}</h3>
    </div>
  </k-dialog>
</template>

<script>
import apiService from "@/api/api.service";
import { Dialog } from "@progress/kendo-vue-dialogs";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  emits: ["hide"],

  props: {
    visibleDialog: Boolean,
    loinfo: Object,
  },
  components: {
    "k-dialog": Dialog,
    VueDatePicker,
  },
  data: function () {
    return {
      availableBookingTimes: [],
      busyBookingTimesStart: [],
      busyBookingTimesEnd: [],
      selectedAppointment: [],
      bookingDays: [],
      interval: 0,
      durationActive: true,
      calendarActive: false,
      apptInfoActive: false,
      confirmationMsgActive: false,
      dateStart: "",
      dateEnd: "",
      meetingEnd: "",
      subject: "",
      message: "",
      location: "",
      clientEmail: "",
      clientName: "",
      isOnline: false,
      errorPopup: false,
      errorMessage: "",
      customTitleBar: "titleTemplate",
      selectedDay: "",
    };
  },
  methods: {
    getAvailability(interval) {
      this.dateStart = new Date();
      this.dateStart.setMinutes(0, 0, 0);
      this.dateStart.setHours(this.dateStart.getHours() + 1);
      this.dateEnd = new Date(this.dateStart);
      this.interval = interval;
      this.dateEnd.setDate(this.dateEnd.getDate() + 30);
      console.log(this.dateStart);
      console.log(this.dateEnd);
      return apiService
        .GetAvailableBookingTimes(
          this.loinfo.email,
          interval,
          this.dateStart.toISOString(),
          this.dateEnd.toISOString(),
        )
        .then((res) => {
          var result = res.data.value[0];
          this.availableBookingTimes = [];
          this.bookingDays = [];
          for (let i = 0; i < result.scheduleItems.length; i++) {
            if (result.scheduleItems[i].status !== "free") {
              var dateStart = new Date(result.scheduleItems[i].start.dateTime);
              this.busyBookingTimesStart.push(dateStart);
              var dateEnd = new Date(result.scheduleItems[i].end.dateTime);
              this.busyBookingTimesEnd.push(dateEnd);
            } else {
              const dateStart = new Date(
                result.scheduleItems[i].start.dateTime,
              );
              const dateEnd = new Date(result.scheduleItems[i].end.dateTime);
              let isAvailable = true;
              for (let i = 0; i < this.busyBookingTimesStart.length; i++) {
                if (
                  (this.busyBookingTimesStart[i] <= dateStart &&
                    dateStart < this.busyBookingTimesEnd[i]) ||
                  (this.busyBookingTimesStart[i] <= dateEnd &&
                    dateEnd < this.busyBookingTimesEnd[i])
                ) {
                  isAvailable = false;
                }
              }
              if (isAvailable) {
                this.availableBookingTimes.push(dateStart);
                const day = dateStart.toLocaleString("en-US", {
                  weekday: "short",
                  month: "2-digit",
                  day: "2-digit",
                });
                if (!this.bookingDays.includes(day)) {
                  this.bookingDays.push(day);
                }
              }
            }
            console.log("Booking days: " + this.bookingDays);
            this.durationActive = false;
            this.calendarActive = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.errorPopup = true;
          console.log(error);
        });
    },

    selectAppointment(item) {
      this.selectedAppointment.push(item);
      this.apptInfoActive = true;
      this.calendarActive = false;
      console.log(item, this.selectedAppointment);
    },
    bookAppointment() {
      console.log(this.selectAppointment);
      this.meetingEnd = new Date(
        this.selectedAppointment[0].getTime() + this.interval * 60000,
      );
      this.apptInfoActive = false;
      this.confirmationMsgActive = true;
      return apiService
        .BookAppointment(
          this.subject,
          this.message,
          this.selectedAppointment[0].toISOString(),
          this.meetingEnd,
          this.location,
          this.loinfo.email,
          this.loinfo.first_name,
          this.clientEmail,
          this.clientName,
          this.isOnline,
        )
        .then((res) => {
          var result = res.data;
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage = error.message;
          this.errorPopup = true;
        });
    },
    closeApptDialog() {
      this.$emit("hide");
      this.durationActive = true;
      this.calendarActive = false;
      this.apptInfoActive = false;
      this.confirmationMsgActive = false;
      this.dateStart = "";
      this.dateEnd = "";
      this.meetingEnd = "";
      this.subject = "";
      this.message = "";
      this.location = "";
      this.clientEmail = "";
      this.clientName = "";
      this.isOnline = false;
      this.selectedDay = "";
      this.interval = 0;
    },
    goBack() {
      if (this.calendarActive) {
        this.calendarActive = false;
        this.durationActive = true;
        this.interval = 0;
      } else {
        this.apptInfoActive = false;
        this.calendarActive = true;
      }
      this.selectedDay = "";
    },
    scroll(anchor) {
      const element = document.getElementById(anchor);
      setTimeout(() => {
        element.scrollIntoView();
      }, 50);
    },
  },
  computed: {
    appointmentDay: {
      get() {
        var d = new Date(this.selectedDay);
        return d;
      },
    },
  },
};
</script>

<style>
@import "/src/assets/css/LOBody.css";
h2 {
  margin: 0;
}

.dp__flex_display {
  display: block;
}
.k-window-content,
.k-prompt-container {
  overflow: hidden;
}

.k-dialog-titlebar {
  border-radius: 5px;
}
.scheduler-dialog {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.scheduler-day {
  width: 10vw;
  border-radius: 3px;
  margin: 5px;
}
.scheduler-day h3 {
  font-size: 1.2rem;
}
.scheduler-dialog .k-dialog {
  width: 60vw;
  height: 75vh;
  border-radius: 5px;
}
.k-window-actions .k-dialog-actions {
  display: none;
}
.message-popup {
  width: 300px;
  height: 80px;
  font-size: 1.2rem;
}
.center-column {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
}
.scroll-container {
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  scrollbar-width: thin;
}
.duration-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background-color: #8cbeff;
  border-radius: 3px;
  width: 25%;
  height: 80px;
  padding: 10px;
  margin: 10px;
  color: white;
  cursor: pointer;
}

.day-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background-color: #3769ac;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  padding: 10px 0;
  margin: 10px 0;
  color: white;
  cursor: pointer;
}

.back-button {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 100%;
  top: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: #3769ac;
  border-radius: 3px;
  width: 70px;
  height: 50px;
  color: white;
  cursor: pointer;
}
.appointment-container {
  flex: 2;
  margin: 5px;
  overflow: hidden;
  height: 100%;
}
.meeting-times-container {
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: center;
  height: 95%;
}
.scheduler-dialog .one-pane p {
  font-size: 1.2rem;
}
.time-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50px;
  font-size: 1.5rem;
  margin: 2px;
  padding: 10px 0;
  border-radius: 3px;
  background-color: #8cbeff;
  color: white;
  cursor: pointer;
}
.time-button p {
  margin: 5px 0;
}
.schedule-input-container {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
}
.schedule-input-field {
  border: 1px solid gray;
  border-radius: 5px;
  flex: auto;
  height: 50px;
  margin: 10px;
}

.scheduler-dialog .one-pane {
  padding: 0;
}

.scheduler-title {
  font-size: 2rem;
  justify-content: center;
}

@media (max-width: 1000px) {
  .scheduler-dialog .two-pane-vertical {
    margin: 0;
  }
  .duration-button {
    flex-direction: column-reverse;
  }
  .k-window-content,
  .k-prompt-container {
    overflow: scroll;
  }
  .appointment-container {
    overflow: auto;
    height: auto;
  }
  .meeting-times-container {
    margin-top: 260px;
  }
}

@media (max-width: 905px) {
  .time-button {
    font-size: 1rem;
  }
  .scheduler-title {
    font-size: 1rem;
  }
}
@media (max-width: 750px) {
  .day-button {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .scheduler-day {
    width: 16%;
  }
  .scheduler-day h3 {
    font-size: 1rem;
  }
  .time-button {
    padding: 3px;
  }
  .scheduler-dialog .one-pane p {
    font-size: 0.8rem;
  }

  .scheduler-title {
    font-size: 1.3rem;
  }
  .duration-button {
    font-size: 1.3rem;
  }
  .schedule-input-container {
    flex-direction: column;
  }
  .scheduler-dialog .k-dialog {
    width: 100vw;
    height: 70vh;
  }
}
@media (max-width: 375px) {
  .back-button {
    font-size: 0.8rem;
    width: 50px;
    height: 30px;
  }
  .scheduler-title {
    font-size: 1rem;
  }
}
</style>
