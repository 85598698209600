<template>
    <div class="apply-save">
        <k-notification-group class="apply-save__notification-container">
            <k-fade :appear="successNotification">
                <k-notification v-if="successNotification"
                                :type="{ style: 'success', icon: true }"
                                :closable="true"
                                @close="onCloseNotification('successNotification')">
                <span>{{ notificationMsg }}</span>
                </k-notification>
            </k-fade>
        </k-notification-group>

        <div v-if="applicationsLoading" id="loaderContainer" class="app-loader-container">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <div class="header" style="background-color: white; justify-content: space-between;">
            <a :href="loinfo?.header_link">
                <img :src="loinfo?.main_logo" style="padding-left: 5px" />
            </a>
            <div style="display: flex; flex-direction: row; height: 100%; justify-content: center; align-items: center;">
                <div class="two-pane-vertical lo-contact">
                    <div class="" style="padding: 5px;">
                        <strong>{{ loinfo?.first_name }} {{ loinfo?.last_name }}</strong>
                    </div>
                    <div>
                        {{ loinfo?.phone_number }}
                    </div>
                </div>
                <img class="header-img" :src="loinfo?.app_header_image ? loinfo?.app_header_image : loinfo?.lo_image" />
            </div> 
        </div>
        <div class="apply-save-body">
            <div class="em-widget-container" v-if="showWidget">
                <div id="widget"
                    class="em-widget"
                    data-width="100%"
                    data-height="550px"
                    data-min-height="550px"
                    data-site-id="1297790669"
                    data-name="ecc-loan-app"
                    :data-config=appWorkflow
                    data-aria-label="My Loan Application"
                ></div>
            </div>

            <div v-if="showGoToLoanLaunch" class="continue-app-container">
                <p><strong>You have {{ userApplications.length }} application<span v-if="userApplications.length > 1">s</span> pending completion.</strong></p>
                <p>Would you like to continue an application or start a new one?</p>
                <div class="application-btns">
                    <a href="https://lo-sites.goldstarfinancial.com/application.html" target="_blank"><k-button>Continue Application</k-button></a>
                    <k-button @click="startNewApp">Start New Application</k-button>
                </div>
            </div>
        </div>
        <lo-footer v-if="loinfo" :loinfo="loinfo" />
    </div>
</template>

<script>
    import { Button } from '@progress/kendo-vue-buttons';
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Loader } from "@progress/kendo-vue-indicators";

    import LOFooter from "../components/LOFooter.vue";

    import apiService from '../api/api.service.js';

    export default {
        data() {
            return {
                applicationsLoading: true,
                appWorkflow: null,
                larCheckIntervalId: null,
                loinfo: null,
                loLar: null,
                notificationMsg: 'Test Message',
                showGoToLoanLaunch: false,
                successNotification: false,
                showWidget: false,
                user: null,
                userApplications: [],
                workflowId: null
            }
        },
        mounted() {
            this.showNotification('Successfully logged into LoanLaunch');
            this.loadData();
        },
        beforeUnmount() {
            clearInterval(this.larCheckIntervalId);
        },
        methods: {
            checkLar() {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });

                let lar = params.lar;

                if (!lar) {
                    this.$router.replace({ name: 'applysave', query: {...this.$route.query, lar: this.loLar}});
                }
            },
            async getApplicationDraftsByLastName(lastName) {
                const token = await this.$auth0.getAccessTokenSilently();

                return apiService.GetApplicationDraftsByLastName(lastName, token)
                    .then(res => {
                        let applications = res.data?.data ?? [];

                        this.userApplications = applications.filter(app => {
                            return (app.borrower.emailAddressText === this.user.usr_email || app.coBorrower.emailAddressText === this.user.usr_email) && app.loanOfficerId === this.loinfo.lar;
                        });

                        if (this.userApplications.length > 0) {
                            this.showGoToLoanLaunch = true;
                        } else {
                            this.showWidget = true;
                        }

                        this.applicationsLoading = false;
                    }).catch(error => {
                        console.log(error)
                    });
            },
            async loadData() {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });

                this.workflowId = params.workflowid;
                this.loLar = params.lar;
                this.appWorkflow = '{"workFlowId":"' + this.workflowId + '","externalLoginWindow":"true"}'; 

                this.loinfo = this.$store.state.loInfo;

                if (!this.loinfo) {
                    await this.$store.dispatch('getLOInfo');
                    this.loinfo = this.$store.state.loInfo;
                }

                if (!this.loLar && this.loinfo) {
                    this.loLar = this.loinfo.lar;
                    this.$router.replace({ name: 'applysave', query: {...this.$route.query, lar: this.loLar}});
                }

                if (this.loLar) {
                    this.larCheckIntervalId = setInterval(() => {
                        this.checkLar();
                    }, 3000);
                }

                this.user = this.$auth0.user.value;

                if (this.user.family_name) {
                    this.getApplicationDraftsByLastName(this.user.family_name);
                } else {
                    this.applicationsLoading = false;
                }
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                this.successNotification = false;
                }, 4000);
            },
            startNewApp() {
                this.showGoToLoanLaunch = false;
                this.showWidget = true;
            }
        },
        components: {
            'lo-footer': LOFooter,
            'k-button': Button,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-loader': Loader
        }
    }
</script>

<style>
    @import "/src/assets/css/homeView.css";
    @import '../assets/css/applySave.css';   
</style>

<style scoped>
    @media (max-width: 450px){
        body{
            margin-top: 0 !important;
        }
    }
</style>