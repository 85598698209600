import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../Views/HomeView.vue";
import LoanProgram from "../Views/LoanProgram.vue";
import Calculators from "../Views/Calculators.vue";
import Apply from "../Views/Apply.vue";
import InfoLinks from "../Views/InfoLinks.vue";
import ContactForm from "../Views/ContactForm.vue";
import ApplySave from "../Views/ApplySave.vue";
import RadiantView from "../Views/RadiantView.vue";
import { authGuard } from "@auth0/auth0-vue";
import apiService from "../api/api.service.js";


// var lar = "jnewton";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/loanprograms",
    name: "loanprograms",
    component: LoanProgram,
  },
  {
    path: "/calculators",
    name: "calculators",
    component: Calculators,
  },
  {
    path: "/apply",
    name: "apply",
    component: Apply,
  },
  {
    path: "/applysave",
    name: "applysave",
    component: ApplySave,
    beforeEnter: authGuard
  },
  {
    path: "/info",
    name: "info",
    component: InfoLinks,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactForm
  },
  {
    path: "/radiant",
    name: "radiant",
    component: RadiantView
  }
];
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
const larFromQuery = params.lar;
const loinfo = await apiService.GetLOPage(larFromQuery);


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve(async to => {
  if (to.name === "home" && loinfo.data.data[0].radiant) {
    return '/radiant?lar=' + larFromQuery;
  }
})

export default router;
