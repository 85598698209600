import apiService from '@/api/api.service';
import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            loInfo: null,
            loLar: null
        }
    },
    mutations: {
        updateLOInfo(state, loInfo) {
            state.loInfo = loInfo;
        },
        updateLOLar(state, lar) {
            state.loLar = lar;
        }
    },
    actions: {
        async getLOInfo({ commit, state }) {
            if (!state.loLar) {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });
                
                commit('updateLOLar', params.lar);
            }

            if (state.loLar) {
                return apiService.GetLOPage(state.loLar)
                    .then(res => {
                        if (res?.data?.data) {
                            commit('updateLOInfo', res.data.data[0]);
                        }
                    }).catch(error => {
                        console.log(error);
                    })

            }
        }
    }
})