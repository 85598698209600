import axios from "axios";

//var API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
//var API_ENDPOINT = "https://localhost:7275/";

export default {
  async GetLOPage(lar) {
    return axios.get(
      process.env.VUE_APP_API_ENDPOINT + "api/LOWebsites/GetLOPage?lar=" + lar,
      {},
    );
  },
  GetReviews(azure_id) {
    return axios.get(
      process.env.VUE_APP_API_ENDPOINT +
      "api/LOWebsites/GetReviews?azure_id=" +
      azure_id,
      {},
    );
  },
  GetApplicationDraftsByLastName(lastName, token) {
    return axios.get(process.env.VUE_APP_API_ENDPOINT + "api/Applications/GetApplicationDraftsByLastName?lastName=" + lastName, {
      headers: {
        "Authorization": "Bearer " + token
      }
    });
  },
  GetAvailableBookingTimes(scheduleId, interval, dateStart, dateEnd) {
    return axios.post(
      process.env.VUE_APP_API_ENDPOINT +
      "api/GraphAPI/GetAvailableBookingTimes",
      {
        Schedules: [scheduleId],
        StartTime: {
          DateTime: dateStart,
          TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        EndTime: {
          DateTime: dateEnd,
          TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        AvailabilityViewInterval: interval,
      },
    );
  },
  BookAppointment(
    subject,
    message,
    dateStart,
    dateEnd,
    location,
    loEmail,
    loName,
    clientEmail,
    clientName,
    isOnline,
  ) {
    return axios.post(
      process.env.VUE_APP_API_ENDPOINT +
      "api/GraphAPI/BookAppointment?sendingUserEmail=" +
      loEmail +
      "&sendingUsersFirstName=" +
      loName,
      {
        subject: subject,
        body: {
          contentType: "HTML",
          content: message,
        },
        start: {
          dateTime: dateStart,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        end: {
          dateTime: dateEnd,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        location: {
          displayName: location,
        },
        attendees: [
          {
            emailAddress: {
              address: clientEmail,
              name: clientName,
            },
            type: "required",
          },
        ],
        allowNewTimeProposals: true,
        isOnlineMeeting: isOnline,
        onlineMeetingProvider: "teamsForBusiness",
        transactionId: "",
      },
    );
  },
  InsertUrlLog(log) {
    return axios.post(process.env.VUE_APP_API_ENDPOINT + "api/Logging/InsertUrlLog", log);
  },
  GetIPAddress() {
    return axios.get("https://api.ipify.org/?format=json");
  }
};
