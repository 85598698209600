import { createApp } from "vue";
import router from "./router";
import store from './store'
import App from "./App.vue";
import Terms from "./components/termsOfUse.vue";
import ConsumerPrivacy from "./components/consumerPrivacy.vue";
import WebsitePrivacy from "./components/websitePrivacy.vue";
import CompanyLicenses from "./components/companyLicenses.vue";
import CCPA from "./components/CCPA.vue";
import DoNotSell from "./components/doNotSell.vue";
import { createAuth0 } from '@auth0/auth0-vue';

const app = createApp(App);

app.use(store);
app.use(router);

var connection = "4GwNHCVnLraecXf3ioh0BGSlitxlkRUg"
var type = 'SMS';

app.use( 
    createAuth0({
      domain: 'dev-3a0i6cew7k1jil3p.us.auth0.com',
      clientId: connection,
      connection:type,
      authorizationParams: {
          redirect_uri: window.location.href,
          audience:"https://dev-3a0i6cew7k1jil3p.us.auth0.com/api/v2/"
      }
    })
  )

app.mount("#app");

app.component("terms-of-use", Terms);
app.component("consumer-privacy", ConsumerPrivacy);
app.component("website-privacy", WebsitePrivacy);
app.component("company-licenses", CompanyLicenses);
app.component("ccpa-privacy", CCPA);
app.component("do-not-sell", DoNotSell);
