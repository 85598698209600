<template>
  <div v-if="!loaded" style="display: flex; justify-content: center; align-items: center; height: 50vh; width: 100%;">
    <k-loader :size="'large'" :type="'converging-spinner'"></k-loader>
  </div>
  <Fade :appear="loaded">

    <div>
      <LOHeader
        @show="showContactFormDialog"
        @lock="lockScroll"
        :loinfo="loinfo"
      ></LOHeader>
  
      <LOBOdy
        :loinfo="loinfo"
        :reviews="reviews"
        :aboutActive="aboutActive"
        :calculatorActive="calculatorActive"
        @show="showApptSchedulerDialog"
      ></LOBOdy>
  
      <LOFooter :loinfo="loinfo"></LOFooter>
  <!-- 
      <contactForm
        :visibleDialog="contactFormDialog"
        @hide="hideContactFormDialog"
        :loinfo="loinfo"
      ></contactForm> -->
      <apptScheduler
        :loinfo="loinfo"
        :visibleDialog="apptSchedulerDialog"
        @hide="hideApptSchedulerDialog"
      ></apptScheduler>
    </div>
  </Fade>
</template>

<script>
import LOBOdy from "../components/LOBody.vue";
import LOHeader from "../components/LOHeader.vue";
import LOFooter from "../components/LOFooter.vue";
// import contactForm from "../components/contactForm.vue";
import apiService from "../api/api.service.js";
import apptScheduler from "@/components/apptScheduler.vue";
import { Loader } from '@progress/kendo-vue-indicators';
import { Fade } from '@progress/kendo-vue-animation';


export default {
  data: function () {
    return {
      contactFormDialog: false,
      apptSchedulerDialog: false,
      loinfo: [],
      reviews: [],
      larFromQuery: "",
      aboutActive: true,
      calculatorActive: false,
      loaded: false
    };
  },
  name: "HomeView",
  props: {
    msg: String,
  },
  components: {
    LOBOdy,
    LOHeader,
    LOFooter,
    // contactForm,
    apptScheduler,
    'k-loader': Loader,
    'Fade': Fade
  },
  methods: {
    showContactFormDialog() {
      this.contactFormDialog = true;
      document.body.classList.add("locked");
    },
    hideContactFormDialog() {
      this.contactFormDialog = false;
      document.body.classList.remove("locked");
    },
    showApptSchedulerDialog() {
      this.apptSchedulerDialog = true;
      apptScheduler.durationActive = true;
      document.body.classList.add("locked");
    },
    hideApptSchedulerDialog() {
      this.apptSchedulerDialog = false;
      document.body.classList.remove("locked");
    },
    lockScroll() {
      if (document.body.classList.contains("locked")) {
        document.body.classList.remove("locked");
      } else {
        document.body.classList.add("locked");
      }
    },
    async getLOInfo() {
      return await apiService
        .GetLOPage(this.larFromQuery)
        .then((res) => {
          this.loinfo = res.data.data[0];
          document.title =
            this.loinfo.first_name +
            " " +
            this.loinfo.last_name +
            " " +
            (this.loinfo.nmls ? this.loinfo.nmls : "");

          this.getReviews();
          
          // this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReviews() {
      return apiService
        .GetReviews(this.loinfo.azure_ID)
        .then((res) => {
          this.reviews = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    swapBodyPanel() {
      this.aboutActive = !this.aboutActive;
      this.calculatorActive = !this.calculatorActive;
    },
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.larFromQuery = params.lar;
  },
  mounted() {
    this.getLOInfo().then(() => setTimeout(() => this.loaded = true, 1000));
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/src/assets/css/homeView.css";
</style>
